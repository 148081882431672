<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="mini" style="width: 100%" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option v-for="item in orderStatusMap" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="包装类型">
          <el-select class="item-choose" v-model="dataForm.packType" size="mini" @change="getDataList()" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.pack_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="请输入客户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDataList"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="卸货地址">
          <el-input v-model="dataForm.giveAddress" placeholder="卸货地址" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"  :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight" height="59vh" border size="small" :span-method="arraySpanMethod" v-loading="dataListLoading" style="width: 100%;">
        <el-table-column label="订单号"  width="105" prop="orderInfo.orderNo" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName"  width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货时间" prop="orderInfo.takeGoodsDate" width="105" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="所需车型" :formatter="dictConvert" prop="orderInfo.vehicleType" width="80" align="center"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货方"  prop="factoryName" align="left" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货地址"  prop="fullAddress" align="left" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系人"  prop="contactName"   width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系电话" prop="contactPhone" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货注意事项" prop="takeRemark" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货联系人" prop="giveAddressInfo.contactName" width="70" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column label="收货电话" prop="giveAddressInfo.contactPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum"  width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume"  width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight"  width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物尺寸(长/宽/高)" prop="goods.length" width="110"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
          </template>
        </el-table-column>
        <el-table-column label="包装类型"  width="80" :show-overflow-tooltip="true" :formatter="manyDictConvert" prop="orderGoods.packType" align="left">
        </el-table-column>
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo"  align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单时间" prop="orderInfo.commitTime" align="left" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="内部备注"  prop="orderInfo.companyRemark" align="left" width="100" :show-overflow-tooltip="true">
        </el-table-column>
       <el-table-column prop="status" align="center" label="状态" fixed="right" width="50" :show-overflow-tooltip="true">
         <template slot-scope="scope">
           <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
           <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
           <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
           <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
           <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
           <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
           <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
         </template>
       </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="55" label="操作">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="detail(scope.row, 'view')">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { newAuth } from '@/utils'
import qs from 'qs'

export default {
  data () {
    return {
      dataForm: {
        xorderType: null,
        giveAddress: null,
        takeGoodsTime: null,
        packType: null,
        customerName: null,
        status: null,
        vehiclePlate: null,
        customerIdList: []
      },
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      listType: 3,
      getIndex: -1,
      orderStatusMap: [
        { id: 1, name: '待审核' },
        { id: 2, name: '调度中' },
        { id: 3, name: '已调度' },
        { id: 4, name: '待出车' },
        { id: 5, name: '已出车' },
        { id: 6, name: '已到达' },
        { id: 7, name: '已装货' },
        { id: 15, name: '卸货到场' },
        { id: 8, name: '已签收' },
        { id: 9, name: '异常结束' },
        { id: 11, name: '订单退回' }
      ]
    }
  },
  components: {
  },
  created () {
    // this.setDefaultDate()
  },
  activated () {
    if (!newAuth('order:manager:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    // this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'orderType': this.dataForm.orderType,
        'giveAddress': this.dataForm.giveAddress,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'packType': this.dataForm.packType,
        'status': this.dataForm.status,
        'customerName': this.dataForm.customerName,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findAllOrderList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.$emit('getTotal')
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo
        }
      })
    }
    // // 新增 / 修改
    // addOrUpdateHandle (id) {
    //   this.addOrUpdateVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.addOrUpdate.init(id)
    //   })
    // }
  }
}
</script>
